import React, { useState } from "react";
import "./connectAccount.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { apiPost } from '../utilities/api/axios';
import { ToastContainer, toast } from "react-toastify";

function ConnectAccountModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const accountData = location.state ? location.state.accountData : [];
  const sessionIdParsed = location.state ? location.state.sessionIdPassed : [];

  const [selectedAccountIndex, setSelectedAccountIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleOptionChange = (index) => {
    setSelectedAccountIndex(index);
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      if (selectedAccountIndex !== null) {
        const sessionId1 = localStorage.getItem('sessionId');
        // const data = {
        //   id: sessionId1,
        //   sessionId: sessionId1,
        //   accIndex: selectedAccountIndex.toString(),
        // };
          const accIndexData = {
            accIndex: selectedAccountIndex.toString(),
            id: sessionId1, 
            sessionId: sessionIdParsed,
          };
          console.log("index data",accIndexData)
          const accIndexResponse = await apiPost(
            'https://linked-landing-e581a0da63a9.autoidleapp.com/index',
            accIndexData,
            {},
            true,
            {}
          );
          if (accIndexResponse && accIndexResponse.accIndexData) {
            console.log("AccIndex:", accIndexResponse.accIndexData);
          
          setIsLoading(false);
          navigate("/pages/account-connected");
        } else {
          setIsLoading(false);
          console.error("An error occurred while connecting the account.");
          toast.error("An error occurred. Please try again later.");
        }
      } else {
        setIsLoading(false);
        console.error("No account selected.");
        toast.error("Please select an account.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error connecting account:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="enter-otp-modal">
      <div>
        <div className="modal-wrapper">
          <div className="choose-bank">
            <p className="choose-your-bank" style={{fontSize:"12px"}}>Choose your bank</p>
          </div>
          <div style={{ padding: "0px 21px 21px 21px" }}>
            <div className="connect-accounts-image">
              <div className="connect-accounts">
                <p className="connect-one">Connect one or more accounts</p>
                <p className="account-name" style={{fontSize:"10px"}}>Account name: <span style={{ color: "#151F32" }}>{accountData && accountData.length > 0 ? accountData[0].name : ''}</span></p>
              </div>
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{ width: "24px", height: "25.4px" }}
              />
            </div>
            <div className="account-type-container">
              {accountData && accountData.length > 0 && accountData.map((account, index) => (
                <div className="account-type" key={index} onClick={() => handleOptionChange(account.index)}>
                  <div className="account-type-text">
                    <p className="type-of-account">{account.type}({account.currency})</p>
                    <p className="account-number">{account.accountNumber}</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value={account.type}
                    checked={selectedAccountIndex === account.index}
                    onChange={() => handleOptionChange(account.index)}
                  />
                </div>
              ))}
            </div>
            <button className="btn" style={{ background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
              {isLoading ? (
                <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                </>
              )}
            </button>
            <div className="secured-flick">
              <img
                src={process.env.PUBLIC_URL + "/images/secured-by-flick.svg"}
                alt="flick"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ConnectAccountModal;
