import React, { useState, useEffect } from "react";
import "./connectAccount.scss";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { apiPost } from "../utilities/api/axios";
import PersonalBankingData from "./PersonalBanking.json";
import BusinessBankingData from "./BusinessBanking.json";
import OtpModal from "./otp/OtpModal";
import SecretModal from "./otp/SecretModal";
import { useBackground } from "./BackgroundProvider";
// import Camera from "./Camera";

function timeout(number) {
  return new Promise((res) => setTimeout(res, number));
}

function EnterCredentials() {
  const { backgroundImage } = useBackground();
  const pageStyle = {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/" + backgroundImage
    })`,
  };
  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem("selectedBankIcon");
  const selectedBankCode = localStorage.getItem("selectedBankCode");
  const bankingType = localStorage.getItem("bankingType") || "PERSONAL_BANKING";
  const selectedOption =
    localStorage.getItem("selectedOption") || "internet_banking";
  const [isLoading, setIsLoading] = useState(false);
  // const [showTestModal, setShowTestModal] = useState(false);

  const responseBanks = JSON.parse(localStorage.getItem("internalBanks"));
  const bankDataArray = [
    ...responseBanks,
    ...PersonalBankingData,
    ...BusinessBankingData,
  ];
  const [id, setId] = useState("");
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isSecretModalOpen, setIsSecretModalOpen] = useState(false);

  useEffect(() => {
    console.log("Selected Bank Code:", selectedBankCode);
    const savedId = localStorage.getItem("sessionId");
    if (savedId) {
      setId(savedId);
    }
  }, [selectedBankCode]);

  const handleBack = () => {
    navigate("/pages/choose-bank");
  };
  const initialState = {
    bank_code: selectedBankCode,
    code_password: "",
    id: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [isInputClicked, setIsInputClicked] = useState({
    userId: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "token") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };

  const formDataFields = (() => {
    const bankData = bankDataArray.find(
      (bank) => bank.bank_code === selectedBankCode && bank.type === bankingType
    );

    const authMethod = bankData.auth_methods.find(
      (method) => method.type === selectedOption
    );

    if (authMethod && authMethod.ui && authMethod.ui.form) {
      return authMethod.ui.form.map((field, index) => (
        <div
          className="flick-link-inner"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginBottom: "23.5px",
          }}
          key={index}
        >
          <div
            className="input_container"
            onBlur={() => handleInputBlur(field.name)}
          >
            <div className={`input-group ${isInputClicked[field.name] ? "clicked" : ""}`} onClick={() => handleInputContainerClick(field.name)}>
              <input
                type={field.type}
                name={field.name}
                placeholder={field.hint}
                className="input-name"
                onChange={handleInputChange}
                required
              />
            </div>
            <p
              className={`input-label ${isInputClicked[field.name] ? "clicked" : ""}`}>
              {field.label}
            </p>
          </div>
        </div>
      ));
    }

    return null;
  })();

  function delayedPostCall(url, data, options) {
    return new Promise(async (resolve) => {
      try {
        await apiPost(url, data, options, true, {});
        resolve(0);
      } catch (error) {
        console.log(error.data);
        // reject(error);
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const data = {
        ...formData,
        id: id,
        auth_method: selectedOption,
        sessionId: localStorage.getItem("sessionId"),
        // const data = { ...formData, id: id, sessionId: localStorage.getItem('sessionId')
      };

      let url = "https://linked-landing-e581a0da63a9.autoidleapp.com/login-2";
      console.log("---------");
      console.log(data);

      if (responseBanks.some((bank) => bank.bank_code === formData.bank_code)) {
        // If it's an internal bank, don't delay the API call
          const internalResponse = await apiPost(url, data, {}, true, {});
         console.log("TheInternal",internalResponse);

        localStorage.setItem("reference",internalResponse?.data?.data?.reference);
        localStorage.setItem("jobId",internalResponse?.data?.data?.jobId);
        localStorage.setItem("user_Id",internalResponse?.data?.data.userId);
        localStorage.setItem("phoneNumberr", internalResponse?.data?.data?.phoneNumber)

        // const livenessCheckData = {
        //   reference: localStorage.getItem("reference"),
        //   jobId: localStorage.getItem("jobId"),
        //   userId: localStorage.getItem("user_Id"),
        //   phoneNumber: localStorage.getItem("phoneNumberr"),
        //   images: []
        // };
        if (internalResponse && internalResponse.data.data.authMethod === "Liveliness") {
          // Open camera popup
          // window.open("/tesdhvb.html");
          // window.open(`${process.env.PUBLIC_URL}/tesdhvb.html`);
          // setShowTestModal(true);
          navigate("/pages/face")
          // Wait for images from camera
          // window.addEventListener("message", async (event) => {
            // if (event.data.type === "imageArray") {
              // livenessCheckData.images = event.data.images;
              // await timeout(60000);
              // const imagesArray = JSON.parse(localStorage.getItem("images"));
              // const livenessCheckData = { 
              //   reference: localStorage.getItem("reference"),
              //   jobId: localStorage.getItem("jobId"),
              //   userId: localStorage.getItem("user_Id"),
              //   phoneNumber: localStorage.getItem("phoneNumberr"),
              //   images: imagesArray
              // };

              // console.log(imagesArray)
              // // console.log(internalResponse.data.data.authMethod)
              // await timeout(40000); 
              // const livenessResponse = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/liveness-check', livenessCheckData, {}, true, {});
              // console.log(livenessResponse);
              // try {
              //   if (livenessResponse.data.status === 200 && livenessResponse.data.data.success === "true") {
              //     setShowTestModal(false);
              //     setIsLoading(false);
              //     navigate("/pages/account-connected");
              //     if (internalResponse.data.data.isMultipleAccounts === true) {
              //       navigate("/pages/internal-connect-accounts", {
              //         state: {
              //           selectedBankIcon,
              //           selectedBankCode,
              //           loginId: internalResponse.data.data.Id,
              //           accountData: internalResponse.data.data.accounts,
              //           sessionIdPassed: localStorage.getItem("sessionId"),
              //         },
              //       });
              //     } else {
              //       navigate("/pages/account-connected");
              //     }
              //   } else {
              //     setShowTestModal(false);
              //     setIsLoading(false);
              //     toast.error("An error occupied");
              //   }
              // }
              // catch (err) {
              //   setShowTestModal(false);
              //   setIsLoading(false);
              //   console.error("Error:", err);
              //     const errorMessage = err?.response?.data && err?.response?.data?.data?.data?.message && err?.response?.data?.data?.message;
              //     toast.error(errorMessage);
              // }
            // }
          // });
        } 
        
        if (internalResponse && internalResponse.data.data.authMethod === "null" && internalResponse.data.data.isMultipleAccounts === true) {
          setIsLoading(false);
          navigate("/pages/internal-connect-accounts", {
            state: {
              selectedBankIcon,
              selectedBankCode,
              loginId: internalResponse.data.data.Id,
              accountData: internalResponse.data.data.accounts,
              sessionIdPassed: localStorage.getItem("sessionId"),
            },
          });
        } else if (internalResponse && internalResponse.data.data.authMethod === "null" && internalResponse.data.data.isMultipleAccounts === false && internalResponse.data.isMultipleAccounts === false) {
          setIsLoading(false);
          navigate("/pages/account-connected");
        } else if (internalResponse && internalResponse.data.success === true) {
          setIsLoading(false);
          navigate("/pages/account-connected");
        }        
        // all other functions here based on internalResponse //
        /// if this is not an internal call do else
      } else {
        // If it's not an internal bank, delay the API call
        delayedPostCall(url, data, {});
        // if(selectedOption === 'internet_banking') {
        //   url = 'https://linked-landing-e581a0da63a9.autoidleapp.com/login-internet';
        //   console.log(data)
        // }

        // initiate login call without waiting for response  //
        //   const firstApiResponsePromise = delayedPostCall(url, data, {});

        // delayedPostCall(url, data, {});

        console.log(data);
        console.log(data.accountNumber);
        localStorage.setItem("accountNumber", data.accountNumber);
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("cardNum", data.cardNum);

        // set this id cos its usuless to stop throwing a shitty warning
        //   id: id
        localStorage.setItem("usulessId", id);

        //   if ((selectedOption === 'mobile_banking' || selectedOption === 'internet_banking')) {
        const otpCheckData = { id: id, sessionId: localStorage.getItem("sessionId"),};
        console.log("otpCheckData payload");
        console.log(otpCheckData);
          // If it's not an internal bank, delay the API call
          await timeout(2000); //for 2 sec delay
          const otpCheckResponse = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/otp-status', otpCheckData, {}, true, {});
          console.log(otpCheckResponse);
          if ( otpCheckResponse.data.status === 200 && otpCheckResponse.data.data.success === "true") {
            if (otpCheckResponse.data.data.isOtpRequired === true) {
              if (otpCheckResponse.data.data.type === "otp") {
                setIsOtpModalOpen(true);
              } else if (
                otpCheckResponse.data.data.type === "security_question"
              ) {
                console.log(otpCheckResponse.data.data.message.data.form[0]);
                localStorage.setItem(
                  "secret_question",
                  otpCheckResponse.data.data.message.data.form[0].hint
                );
                setIsSecretModalOpen(true);
              }
  
              setIsLoading(false);
              console.log(data.type + " is required");
              return;
            } else {
              setIsOtpModalOpen(false);
              setIsSecretModalOpen(false);
              console.log("OTP is NOT-required");
  
              const grabLoginDatya = {
                auth_id: localStorage.getItem("sessionId"),
              };
  
              const res = await apiPost(
                "https://linked-landing-e581a0da63a9.autoidleapp.com/select-account-status",
                grabLoginDatya,
                {},
                true,
                {}
              );
              console.log("select-account-status response ");
              console.log(res);
              console.log("stepping into the response", res.data);
  
              const id = res.data.id;
              localStorage.setItem("auth_id", id);
              //   localStorage.setItem('sessionId', id);
  
              // check if this response carries success or carries multiple accounts //
              //   if(res && res.data && Array.isArray(res.data.data)) {
              if (
                res.data.status === 200 &&
                res.data.data.isSingleAccount === false
              ) {
                setIsLoading(false);
                navigate("/pages/connect-accounts", {
                  state: {
                    selectedBankIcon,
                    accountData: res.data.data.accounts,
                    sessionIdPassed: localStorage.getItem("sessionId"),
                  },
                });
              } else {
                setIsLoading(false);
                navigate("/pages/account-connected");
              }
  
              setFormData(initialState);
            }
          } else {
            console.log("i don get error ");
            console.log(otpCheckResponse.data);
            console.log(otpCheckResponse.data.data);
            // {
            //     "status": 200,
            //     "message": "success",
            //     "data": {
            //         "isOtpRequired": false,
            //         "message": {
            //             "message": "No mobile banking profile exist for this account. Please register your device",
            //             "status": 400
            //         },
            //         "id": "e4ca3a6b-3d87-4943-a0bb-009794b42a10",
            //         "success": "false"
            //     }
            // }
            // show a toast for status //
            // const errorMessage = err?.response?.data?.msg || 'Please check your login details and try again.';
            toast.error(
              otpCheckResponse.data.data.message.message || "try again."
            );
            setIsLoading(false);
          }
        // if (otpCheckResult.data.isOtpRequired) {
      }

      // // delay this call by 2 seconds //
      // await timeout(2000); //for 1 sec delay
      // const otpCheckResponse = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/otp-status', otpCheckData, {}, true, {});
      // // const otpCheckResult = otpCheckResponse.data;
      // console.log(otpCheckResponse)

      //   }
      // ignore promise call convert this to a stright api call to fetch login response saved in the data base //
      // const res22 =  firstApiResponsePromise;
    } catch (err) {
      setIsLoading(false);
      console.error("Error:", err);
        const errorMessage = err?.response?.data?.message  && err?.response?.data?.data?.message && err?.response?.data?.data?.data?.message ;
        toast.error(errorMessage || 'Please check your login details and try again.');
    }
  };

  return (
    <div className="connect-account-overlay" style={pageStyle}>
      <div
        className={`connect-account-main ${
          isOtpModalOpen || isSecretModalOpen ? "blurred" : ""
        }`}
      >
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + "/images/arrowBack.svg"}
              alt="back"
              style={{ width: "16px", height: "16px" }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              style={{ marginBottom: "27px" }}
              onClick={handleBack}
            />
          </div>
          <div className="enter-credentials-box">
            <div className="img-logo">
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{ width: "60px", height: "62.5px" }}
              />
            </div>
            <div className="enter-credentials">
              <p className="enter-your-credentials">Enter your credentials</p>
            </div>
            <form
              className="flick-link-form"
              style={{ marginTop: "10px" }}
              onSubmit={handleSubmit}
            >
              {formDataFields}
              <button
                className="btn"
                style={{ background: "#2EBDB6" }}
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>
                    Submit
                    <img
                      src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"}
                      alt="arrow btn"
                      style={{ display: "inline", marginLeft: "5px" }}
                    />
                  </>
                )}
              </button>
              <div className="secured-flick">
                <img
                  src={process.env.PUBLIC_URL + "/images/secured-by-flick.svg"}
                  alt="flick"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
      {isOtpModalOpen ? (
        <OtpModal />
      ) : isSecretModalOpen ? (
        <SecretModal />
      ) : null}
      {/* {showTestModal && <Camera />} */}
    </div>
  );
}

export default EnterCredentials;
