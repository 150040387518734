// import PouchDB from 'pouchdb';
import React, { useState, useEffect } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader,FadeLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useBackground } from './BackgroundProvider';
import axios from 'axios';
// import {getEncodedValue, getDecodedValue }  from  '../utilities/api/axios'

function LoginDetails() {
    // const db = new PouchDB('FlickPouchDb');

  useEffect(() => {
    getId();
    const initializeValue = getParam();
    console.log('Param:', initializeValue);
    // eslint-disable-next-line
  }, []);
  const { backgroundImage } = useBackground();
  // eslint-disable-next-line
  const [values, setValues] = useState({})
  const [isProcessing,setIsProcessing] = useState(false)
  const pageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + backgroundImage})`,
  };


  const navigate = useNavigate();

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const getParam = () => {
    const endpointUrl = 'https://linked-landing-e581a0da63a9.autoidleapp.com/initialize';
    const pathSegments = endpointUrl.split('/').filter(segment => segment !== '');
    const param = pathSegments[pathSegments.length - 1]; 
    return param;
  };

  
    const getEncodedValue = (clearText) => {
        return btoa(encodeURI(clearText));
    }

    const getDecodedValue = (encodedValue) =>{
            return decodeURI(atob(encodedValue));
    }

  const getId = async () => {

    setIsProcessing(true)
    const params  = window.location.href;
    const urlId = params.split('/');
    const id = urlId[urlId.length-1]
    try {
      const url = `https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/page-authenticator/${id}`;
      const res = await axios.get(url)
      console.log(res);
      var checkmeSource = ["dashboard", "api"];

        console.log(checkmeSource.indexOf(res?.data?.data.source) > -1); //true

      if(res?.data?.verificationStatus==="verified" && checkmeSource.indexOf(res?.data?.data.source) > -1){
      
        console.log("passed");
        localStorage.setItem("pageName",res?.data?.data.source === "api" ? res?.data?.business_name : res?.data?.data?.pageName);
        localStorage.setItem("pageDescription",res?.data?.data?.description);
        localStorage.setItem("email",res?.data?.data?.emailaddress);
        localStorage.setItem("businessName",res?.data?.business_name);
        localStorage.setItem("linkId", res?.data?.data?.linkId)
        // localStorage.setItem("internalBanks", res?.data?.internalBanks)
        localStorage.setItem("internalBanks", JSON.stringify(res?.data?.internalBanks));


        var encodedLivePk = getEncodedValue(res?.data?.data.livePublicKey);
        localStorage.setItem("livePublicKey", encodedLivePk);
            // localStorage.setItem("livePublicKey", res?.data?.data.livePublicKey)

        localStorage.setItem("source", res?.data?.data.source)
        localStorage.setItem("is_portco", res.data.is_portco === true ? "yes" : "no")

        const result = {
          // key: res?.data?.data.livePublicKey,
          id:id
        }
        setValues(result);
        const strResult = JSON.stringify(result);
        localStorage.setItem("auth",strResult);
        console.log("dipo is_portco ", res.data.is_portco, " source: ", res?.data?.data.source);

        if (res.data.is_portco === true || res?.data?.data.source === "api") {
            console.log("you have been excluded");
        //   saveIsPortco(true);
          // Initialize the form with data from the response
            const formDataAuto = {
                "firstName": localStorage.getItem('pageName'),
                "lastName": localStorage.getItem('pageDescription'),
                "email": localStorage.getItem('email'),
            };

            setFormData(formDataAuto); 

            var livePublicKey = localStorage.getItem("livePublicKey")
            // var livepubblicKey = localStorage.getItem("livePublicKey");
            if (livePublicKey !== null){
                livePublicKey = getDecodedValue(livePublicKey);
            }
            // const livePublicKey = livepubblicKey;


            const headers = {
                Authorization: `Bearer ${livePublicKey}`
            };

            const locVar = JSON.parse(localStorage.getItem('auth'));

            const url = 'https://linked-landing-e581a0da63a9.autoidleapp.com/initialize';

            const res = await axios.post(url, { ...formDataAuto, pageId: locVar.id }, {
                headers: headers
            });

            const id = res.data.id;
            localStorage.setItem('userId', id);
            localStorage.setItem('sessionId', id);

            if (res && res.data) {
                const responseData = res.data;
                console.log('Response Data:', responseData);
                console.log('Session:', id);
            }

            // toast.success('Processing');
            setTimeout(() => {
                navigate('/pages/super-wallet');
            }, 1000);
          
        }

        setTimeout(() => {
            setIsProcessing(false);
        }, 2000);

      } else if (res?.data?.verificationStatus !== "verified") {
        return navigate('/invalid');
      }
    } catch (error) {
      return navigate('/invalid');
    }
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };

  const handleContinue = async (e) => {
    e.preventDefault();
  
    try {
      setIsLoading(true);
      const data = { ...formData };
      console.log('Data:', data);
      
    //   const publicKeyDoc = await db.get('flickmydoc');

        var livePublicKey = localStorage.getItem("livePublicKey")
        // var livepubblicKey = localStorage.getItem("livePublicKey");
        if (livePublicKey !== null){
            livePublicKey = getDecodedValue(livePublicKey);
        }

      const headers = {
        Authorization: `Bearer ${livePublicKey}`
      };

      const locVar = JSON.parse(localStorage.getItem('auth'));

      const url = 'https://linked-landing-e581a0da63a9.autoidleapp.com/initialize';
      // const res = await apiPost(url, data, {}, true, {});
      console.log(headers);
      const res = await axios.post(url,{...formData,pageId:locVar.id},{
        headers:headers
      })
      const id = res.data.id;
      localStorage.setItem('userId', id);
      localStorage.setItem('sessionId', id);

      if (res && res.data) {
        const responseData = res.data;
        console.log('Response Data:', responseData);
      }
      toast.success('processing');
      setTimeout(() => {
        setIsLoading(false);
        navigate('/pages/super-wallet');
      }, 2000);
    } catch (err) {
      setIsLoading(false);
      console.error('Error:', err);
      const errorMessage = err?.response?.data?.msg || 'Failed';
      toast.error(errorMessage || 'Failed');
    }
  };


  return (
    <>
    {isProcessing ? (<div className="container_overlay">
        <div className='loading_overlay' style={{background:"grey"}}>
            <div className='loader_icon' >
              <FadeLoader 
                cssOverride={{marginRight:"auto",marginLeft:"auto",marginTop:"50vh"}}
                color="#259792"
              />
            </div>
        </div>
      </div>
      ):
       <div className="connect-account-overlay" style={pageStyle}>
        <div className="connect-account-main">
          <div className="flick-link-box">
            <div className="flick-link-title">
              <div className="flick-link">
              <img src={process.env.PUBLIC_URL + '/images/flick-link-logo.svg'} alt="logo" />
                <p className="link-demo">{localStorage.getItem("businessName")}</p>
                <p className="explore-flick">{" requests "+localStorage.getItem("pageDescription")}</p>
              </div>
            </div>
            <form className="flick-link-form" onSubmit={handleContinue}>
              <div className="flick-link-inner">
                <div className="input_container" onBlur={() => handleInputBlur('firstName')}>
                  <div className={`input-group ${isInputClicked.firstName ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('firstName')}>
                    <input type="text" name="firstName" placeholder="First Name" className={`input-name`} onChange={handleInputChange} required />
                  </div>
                  <p className={`input-label ${isInputClicked.firstName ? 'clicked' : ''}`}>First Name </p>
                </div>
                <div className="input_container" onBlur={() => handleInputBlur('lastName')}>
                  <div className={`input-group ${isInputClicked.lastName ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('lastName')}>
                    <input type="text" name="lastName" placeholder="Last Name" className={`input-name`} onChange={handleInputChange} required />
                  </div>
                  <p className={`input-label ${isInputClicked.lastName ? 'clicked' : ''}`}>Last Name </p>
                </div>
                <div className="input_container" onBlur={() => handleInputBlur('email')}>
                  <div className={`input-group ${isInputClicked.email ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('email')}>
                    <input type="email" name="email" placeholder="Email Address" className={`input-name`} onChange={handleInputChange} required />
                  </div>
                  <p className={`input-label ${isInputClicked.email ? 'clicked' : ''}`}>Email Address </p>
                </div>
              </div>
              <button id="continue-button" className="btn" style={{ background: '#2EBDB6' }} disabled={isLoading}>
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Continue
                    <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }} />
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
      }

    </>
  );
}

export default LoginDetails;