import React from 'react'
import './connectAccount.scss'
import { useNavigate } from "react-router-dom";
import { useBackground } from './BackgroundProvider';


function SuperWallet() {

    const { backgroundImage } = useBackground();

    const pageStyle = {
      backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + backgroundImage})`,
    };
    const navigate = useNavigate();
    // const id = JSON.parse(localStorage.getItem('auth'))
    const handleClick = () => {
      navigate("/pages/select-bank");
    };
    const handleClose = () => {
        navigate("/")
    }

  return (
    <div className='connect-account-overlay' style={pageStyle}>
        <div className='connect-account-main'>
            <div className='connect-account-box'> 
                <div  className='close-button'>
                    <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
                </div>
                <div className='flick-logo'>
                    <img src={process.env.PUBLIC_URL + "/images/flick-black-logo.svg"} alt="close"/>
                </div>
                <p className='super-wallet'>{localStorage.getItem("businessName")} uses <span>Flick</span> to connect your account</p>
                <div className='connect-effortless'>
                   <div className='border-line'>
                        <div className='connect-title'>
                            <img src={process.env.PUBLIC_URL + "/images/connect.svg"} alt="logo"/>
                            <div className='connect-link'>
                                <p className='connect-your-account'>Secure</p>
                                <p className='securely-connect' style={{marginBottom:"18px"}}>Your data is encrypted and your credentials will not be accessible to {localStorage.getItem("businessName")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='connect-title'>
                        <img src={process.env.PUBLIC_URL + "/images/your-data.svg"} alt="logo"/>
                        <div className='connect-link'>
                            <p className='connect-your-account'>You have control</p>
                            <p className='securely-connect'>Flick or {localStorage.getItem("businessName")} cannot share your data or access your funds without your permission</p>
                        </div>
                    </div>
                </div>
                <div className='flick-policy'>
                    <div className='agree-to'>
                        <p className='select-continue'>By selecting “Continue” you agree to the</p>
                        <a href="https://getflick.app/privacy-policy" className='flick-end-user'>Flick End User Privacy Policy</a>
                    </div>
                    <button className="btn" style={{background:"#2EBDB6"}} onClick={handleClick}>
                        Continue
                        <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                    </button>
                </div>

            </div>
        </div>
    </div>
  )
}

export default SuperWallet