import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import PersonalBankingData from './PersonalBanking.json'; 
import BusinessBankingData from './BusinessBanking.json';
import { useBackground } from './BackgroundProvider';

function ChooseYourBank() {
  const { backgroundImage } = useBackground();

  const pageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + backgroundImage})`,
  };

  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const selectedBankCode = localStorage.getItem('selectedBankCode');
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const connectionMethod = (bankCode, methodType) => {
    const responseBanks = JSON.parse(localStorage.getItem('internalBanks'));
    const bankDataArray = [...responseBanks, ...PersonalBankingData, ...BusinessBankingData];
    const bankData = bankDataArray.find(
      (bank) => bank.bank_code === bankCode
    );
    return (
      bankData &&
      bankData.auth_methods.some((method) => method.type === methodType)
    );
  };

  const handleClick = () => {
    if (!selectedOption) {
      toast.error('Please select a connection method');
      return;
    }

    if (!connectionMethod(selectedBankCode, selectedOption)) {
      toast.error(
        `Connection method not avaiable`
      );
      return;
    }

    setIsLoading(true);
    localStorage.setItem('selectedOption', selectedOption);
    if (selectedBankCode) {
      setIsLoading(false);
      navigate('/pages/enter-credentials', {
        state: {
          selectedBankIcon: selectedBankIcon,
          selectedBankCode: selectedBankCode,
        },
      });
    } else {
      setIsLoading(false);
      console.error('Error:', 'Bank code cannot be empty');
    }
  };

  const handleBack = () => {
    navigate('/pages/select-bank');
  };

  return (
    <div className="connect-account-overlay" style={pageStyle}>
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div
            className="flick-logo"
            style={{ position: 'relative', marginTop: '20px' }}
          >
            <img
              src={process.env.PUBLIC_URL + '/images/round-flick-logo.svg'}
              alt="logo"
              style={{ width: '48px', height: '48px' }}
            />
            <img
              src={process.env.PUBLIC_URL + selectedBankIcon}
              alt="bank logo"
              style={{
                position: 'absolute',
                top: '0',
                right: '115px',
                width: '50px',
                height: '50px',
              }}
            />
          </div>
          <p className="selection">Select connection method</p>
          <div className="connect-effortless">
            <div className="border-line">
              <div
                className="connect-title"
                onClick={() => setSelectedOption('internet_banking')}
              >
                <img
                  src={process.env.PUBLIC_URL + '/images/connect.svg'}
                  alt="logo"
                />
                <div className="connect-your-account-link">
                  <p className="connect-your-account">Internet banking</p>
                  <p className="securely-connect" style={{ marginBottom: '18px' }}>
                    Use your web username & password
                  </p>
                </div>
                <input
                  type="radio"
                  name="options"
                  value="internet_banking"
                  checked={selectedOption === 'internet_banking'}
                  onChange={handleOptionChange}
                />
              </div>
            </div>
            <div
              className="connect-title"
              onClick={() => setSelectedOption('mobile_banking')}
            >
              <img
                src={process.env.PUBLIC_URL + '/images/your-data.svg'}
                alt="logo"
              />
              <div className="connect-your-account-link">
                <p className="connect-your-account">Mobile banking</p>
                <p className="securely-connect">
                  Use your username or account number & password
                </p>
              </div>
              <input
                type="radio"
                name="options"
                value="mobile_banking"
                checked={selectedOption === 'mobile_banking'}
                onChange={handleOptionChange}
              />
            </div>
            <button
              className="btn"
              style={{ background: '#2EBDB6' }}
              onClick={handleClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img
                    src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'}
                    alt="arrow btn"
                    style={{ display: 'inline', marginLeft: '5px' }}
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChooseYourBank;
