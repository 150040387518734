import React, { createContext, useContext, useState, useEffect } from 'react';

const BackgroundContext = createContext();

export function useBackground() {
  return useContext(BackgroundContext);
}

export function BackgroundProvider({ children }) {
  const backgroundOptions = [
    "green-bg.svg",
    // "peach-bg.svg",
    // "yellow-bg.svg",
    // "lilac-bg.svg",
    // "purple-bg.svg",
    "army-green-bg.svg",
    // "white-bg.svg",
  ];

  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const randomBackground = backgroundOptions[Math.floor(Math.random() * backgroundOptions.length)];
    setBackgroundImage(randomBackground);
    // eslint-disable-next-line
  }, []);

  const setRandomBackground = () => {
    const randomBackground = backgroundOptions[Math.floor(Math.random() * backgroundOptions.length)];
    setBackgroundImage(randomBackground);
  };

  return (
    <BackgroundContext.Provider value={{ backgroundImage, setRandomBackground }}>
      {children}
    </BackgroundContext.Provider>
  );
}
