import React from 'react'

function InvalidPage() {
  return (
    <div className='error_container'>

        <div className='error_content' style={{marginTop:"5vh", paddingTop:"30px"}}>
        <img src='https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/orange-error-icon-0.png' alt='warning' width="45" />
              <h3 className='title error_invalid_header'>Unable to start this transaction</h3><br />
              <p className="error_invalid">It's either because the link is incorrect or the transaction is already completed</p>
        </div>
        <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' width="20" />
            <div className='secured_content'>
            <p className='secured_p'>Secured by  </p>
                <img src='/images/flickLogo.svg' alt='logo' width="45" />
            </div>
        </div>
    </div>
  )
}

export default InvalidPage