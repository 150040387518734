import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
import LoginDetails from './components/connectAccounts/LoginDetails';
import SuperWallet from './components/connectAccounts/SuperWallet';
import SelectBanks from './components/connectAccounts/SelectBanks';
import ChooseYourBank from './components/connectAccounts/ChooseYourBank';
import EnterCredentials from './components/connectAccounts/EnterCredentials';
import ConnectAccounts from './components/connectAccounts/ConnectAccounts';
import AccountConnected from './components/connectAccounts/AccountConnected';
import { BackgroundProvider } from './components/connectAccounts/BackgroundProvider';
import NotFoundPage from './components/pages/NotFoundPage';
import InvalidPage from './components/pages/InvalidPage';
import InternalConnectAccounts from './components/connectAccounts/InternalConnectAccounts';
import Camera from './components/connectAccounts/Camera';

function App() {
  return (
    <Router>
      <BackgroundProvider> 
        <Routes>
          <Route>
            <Route path="/" element={<NotFoundPage />} />
            <Route path="/invalid" element={<InvalidPage/>} />
            <Route path="/pages/super-wallet" element={<SuperWallet />} />
            <Route path="/pages/select-bank" element={<SelectBanks />} />
            <Route path="/pages/choose-bank" element={<ChooseYourBank />} />
            <Route path="/pages/enter-credentials" element={<EnterCredentials />} />
            <Route path="/pages/connect-accounts" element={<ConnectAccounts />} />
            <Route path="/pages/internal-connect-accounts" element={<InternalConnectAccounts />} />
            <Route path="/pages/face" element={<Camera />} />
            {/* <Route path="/Camera" element={<Camera />} /> */}
            <Route path="/pages/account-connected" element={<AccountConnected />} />
            <Route path="/pages/:id" element={<LoginDetails />} />
            <Route path="/:id" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BackgroundProvider> 
    </Router>
  );
}

export default App;
