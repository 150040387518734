import React, { useState, useEffect } from 'react';
import { useBackground } from './BackgroundProvider';
import { BeatLoader } from "react-spinners";
// import PouchDB from 'pouchdb';

 function AccountConnected() {

    // const db = new PouchDB('FlickPouchDb');
  const { backgroundImage } = useBackground();
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
//   const [isPortco, setIsPortco] = useState(false);

  const pageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + backgroundImage})`,
  };

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace('/');
    });

    return () => {
      window.removeEventListener('popstate', function(event) {
        window.location.replace('/');
      });
    };
  }, []);
  

    // const publicKeyDoc = db.get('flickmydoc');
    // const isPortcoed = publicKeyDoc.is_portco;
    const isPortcoed = localStorage.getItem("is_portco") === "yes" ? true : false;
    // isPortcoed === true ? setIsPortco(true) : setIsPortco(false)
    

  const handleDashboard = () => {
    setIsLoadingDashboard(true);
    window.location.href = 'https://portco.merchant.getflick.co';
  }
  const linkId = localStorage.getItem('linkId')
  const handleLinkAccount = async () => {
    setIsLoadingLink(true);
    // window.location.href = 'https://link.paywithflick.co/pages/79mJS8A3sx';
    window.location.href = `https://link.paywithflick.co/pages/${linkId}`;
    

  //   // THIS CALLS A NEW SESSION ID
  //   try {

  //       const formDataAuto = {
  //           "firstName": res?.data?.data?.pageName,
  //           "lastName": res?.data?.data?.description,
  //           "email": res?.data?.data?.emailaddress
  //       }                
  //       console.log('Data:', formDataAuto);
  //       const locVar = JSON.parse(localStorage.getItem('auth'));
  
  //       const headers ={
  //         Authorization: `Bearer ${locVar.key}`
  //       }
  //       const url = 'https://linked-landing-e581a0da63a9.autoidleapp.com/initialize';
  //       // const res = await apiPost(url, data, {}, true, {});
  //       console.log(headers);
  //       const res = await axios.post(url,{formDataAuto,pageId:locVar.id},{
  //         headers:headers
  //       })

  //       const id = res.data.id;
  //        localStorage.setItem('sessionId', id);

  
  //       if (res && res.data) {
  //         const responseData = res.data;
  //         console.log('Response Data:', responseData);
  //       }
  //       toast.success('processing');
  //       setTimeout(() => {
  //       //   setIsLoading(false);
  //         navigate('/pages/super-wallet');
  //       }, 2000);
  //     } catch (err) {
  //       // setIsLoading(false);
  //       console.error('Error:', err);
  //       const errorMessage = err?.response?.data?.msg || 'Failed';
  //       toast.error(errorMessage || 'Failed');
  //     }
  //     setIsProcessing(false)
  //     navigate('/pages/super-wallet'); 

  }

  return (
    <div className="connect-account-overlay" style={pageStyle}>
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className='success-flick'>
            <img src={process.env.PUBLIC_URL + "/images/success-icon.svg"} alt="logo"/>
            <img src={process.env.PUBLIC_URL + "/images/flick-white-border.svg"} alt="logo" style={{position:"absolute", bottom:"0", right:"100px"}}/>
          </div>

          <div className='connected'>
            <p className='account-connected'>Account Connected!</p>
            <p className='your-account'>Your account has been successfully linked.</p>
          </div>
          {isPortcoed && (
            <div className='portco-btn'>
              <button className="btn-account" onClick={handleLinkAccount} style={{ background: '#2EBDB6' }} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingLink ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Link another account
                  </>
                )}
              </button>

              <button className="btn-account" onClick={handleDashboard} style={{ background: '#EAF8F8', color:"#2EBDB6" }} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingDashboard ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Go to dashboard
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountConnected;

