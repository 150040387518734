import React, { useState, useEffect } from 'react';
import './connectAccount.scss';
import PersonalBankingData from './PersonalBanking.json';
import BusinessBankingData from './BusinessBanking.json'; 
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from 'react-toastify';
import { useBackground } from './BackgroundProvider';

function SelectBanks() {
  const { backgroundImage } = useBackground();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankingType, setBankingType] = useState('PERSONAL_BANKING'); 
  const [combinedBanks, setCombinedBanks] = useState([]);

  useEffect(() => {
    const responseBanks = JSON.parse(localStorage.getItem('internalBanks'));
    const allBanks = [...responseBanks, ...PersonalBankingData, ...BusinessBankingData];
    setCombinedBanks(allBanks);
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const selectBank = (bank_code) => {
    setSelectedBank(bank_code);
  };

  const handleBankingTypeChange = (type) => {
    setBankingType(type);
  };

  const handleClick = () => {
    setIsLoading(true);
    if (selectedBank) {
      const selectedBankInfo = combinedBanks.find((bank) => bank.bank_code === selectedBank);
      if (selectedBankInfo) {
        setIsLoading(false);
        localStorage.setItem('selectedBankIcon', selectedBankInfo.icon);
        localStorage.setItem('selectedBankCode', selectedBankInfo.bank_code);
        localStorage.setItem('bankingType', selectedBankInfo.type); 
        localStorage.setItem('authMethod', selectedBankInfo.auth_methods); 
        navigate("/pages/choose-bank");
        console.log("selectedBank:", selectedBankInfo.type);
        console.log("bank_code:", selectedBankInfo.bank_code);
        console.log("authss:", selectedBankInfo.auth_methods);
      }
    } else {
      setIsLoading(false);
      toast.error("Please select a bank");
    }
  };
  
  const handleBack = () => {
    navigate("/pages/super-wallet");
  };

  const filteredBanks = combinedBanks.filter((bank) =>
    bank.name.toLowerCase().includes(searchTerm)&& bank.type === bankingType
  );

  return (
    <div className='connect-account-overlay' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + backgroundImage})` }}>
      <div className='connect-account-main'>
        <div className='connect-account-box'>
          <div className='choose-bank'>
            <img src={process.env.PUBLIC_URL + "/images/arrowBack.svg"} alt="back" style={{ width: "16px", height: "16px" }} onClick={handleBack}/>
            <p className='choose-your-bank'>Choose your bank</p>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
          <div className='select-banks'>
            <div className='search-banks'>
              <input
                className="search-field"
                type="text"
                placeholder="Search for your bank"
                name="search"
                value={searchTerm}
                onChange={handleInputChange}
              />
              <img src={process.env.PUBLIC_URL + "/images/search-bank.svg"} alt="search" />
            </div>
            <div className='banking'>
              <p
                className={`banking-option ${bankingType === 'PERSONAL_BANKING' ? 'banking-active' : ''}`}
                onClick={() => handleBankingTypeChange('PERSONAL_BANKING')}
              >
                Personal
              </p>
              <p
                className={`banking-option ${bankingType === 'BUSINESS_BANKING' ? 'banking-active' : ''}`}
                onClick={() => handleBankingTypeChange('BUSINESS_BANKING')}
              >
                Business
              </p>
            </div>

            <div className='bank-list'>
              {filteredBanks.map((bank, index) => (
                <div
                  className={`select-bank ${selectedBank === bank.bank_code ? 'selected' : ''}`}
                  key={index}
                  onClick={() => selectBank(bank.bank_code)}
                >
                  <img src={bank.icon} alt="bank" style={{ borderRadius:"50%", width:"24px", height:"24px" }} />
                  <p className={`bank-name ${selectedBank === bank.bank_code ? 'selected-text' : ''}`}>{bank.name}</p>
                </div>
              ))}
            </div>

            <button className="btn" style={{ background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
              {isLoading ? (
                <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SelectBanks;

