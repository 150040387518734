import React from 'react'


function NotFoundPage() {
  return (
    <div className='error_container'>

        <div className='error_content' >
              <h1 className='text' style={{fontSize:"18px"}}>404</h1>
              <h3 className='title'>We couldn't find this page</h3>
        </div>
         <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' width="20" />
            <div className='secured_content'>
                <p className='secured_p'>Secured by  </p>
                <img src='/images/flickLogo.svg' alt='logo' width="40"/>
            </div>
        </div>
    </div>
  )
}

export default NotFoundPage